import React from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { IconButton, Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { snackbarState } from '@store/atoms/snackbar'

export function SnackbarView() {
  const snackbar = useRecoilValue(snackbarState)
  const resetSnackbar = useResetRecoilState(snackbarState)

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbar.isOpen}
      autoHideDuration={8000}
      onClose={() => resetSnackbar()}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={() => resetSnackbar()}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      }
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span id="message-id" data-testid="message">
          {snackbar.message}
        </span>
      }
    />
  )
}
