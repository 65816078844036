import { createTheme, adaptV4Theme } from '@mui/material/styles'
import { cyan, deepPurple, red } from '@mui/material/colors'

export const defaultTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: deepPurple[400],
      },
      secondary: {
        main: cyan[700],
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#f8f9fa',
      },
    },
  }),
)
